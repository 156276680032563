import useMediaQuery from '@/hooks/useMediaQuery';

export const useCatalogBreakpoints = (isFilterOpen: boolean) => {
  const isMobile = useMediaQuery('(max-width: 35rem)');
  const isTablet = useMediaQuery('(min-width: 35rem) and (max-width: 50rem)');

  let gridItemsCount = isFilterOpen ? 4 : 5;

  if (isTablet) {
    gridItemsCount = 3;
  }

  if (isMobile) {
    gridItemsCount = 2;
  }

  return { gridItemsCount, isMobile, isTablet };
};
