import { toRem } from '@/helpers/toRem';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Container,
  HStack,
  Img,
  StyleProps,
  Text,
  VStack
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import Slide from '../Slide';
import { FC, useEffect, useState } from 'react';
import { getFallbackLanguage } from '@/helpers/lang';
import { useExtraProps } from '@/pages/_app';
import { BannerType } from '@/types/api/ge-strapi/banner';
import useTranslation from 'next-translate/useTranslation';
import { HTTP } from '../Http';
import { getCategoriesHeight, getHeaderHeight } from '@/helpers/header';

type FullSliderProps = BoxProps & {
  categoryId?: string;
  sliderHeight?: number;
};
const FullSlider: FC<FullSliderProps> = ({
  categoryId,
  sliderHeight = 379,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: md)');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [banners, setBanners] = useState<BannerType[]>([]);
  const [isBannerError, setIsBannerError] = useState<boolean>(false);
  const { t, lang } = useTranslation();
  const { license } = useExtraProps();
  const customBulletName = 'custom-catalog-bullet';
  const customBulletActiveName = 'custom-catalog-bullet-active';

  const bulletStyles = css`
    .${customBulletName} {
      display: inline-flex;
      width: 8px;
      height: 8px;
      background: ${isMobile ? 'transparent' : 'rgba(0,0,0,.5)'};
      border-radius: 50%;
      margin: 0 5px;
      border: 1px solid ${isMobile ? '#fff' : 'rgba(0,0,0,.5)'};
      transition: background-color 0.3s ease;
    }

    .${customBulletActiveName} {
      background: #fff;
    }
  `;

  const SlideItem = ({
    buttonText,
    buttonUrl,
    content,
    title,
    imageUrl,
    containerStyle,
    target,
    extensionBackgroundColor
  }: {
    imageUrl: string;
    title: string;
    content: string;
    buttonText: string;
    buttonUrl: string;
    containerStyle?: StyleProps;
    target?: string;
    extensionBackgroundColor: string;
  }) => {
    const isDesktop = useMediaQuery('(min-width: md)'); // 768px
    const paddingBottom = 24;
    const paddingTop =
      getHeaderHeight(isDesktop) + getCategoriesHeight() + paddingBottom;

    return (
      <Box
        display={'flex'}
        justifyContent="flex-start"
        sx={{ ...containerStyle }}
      >
        <Box
          position="absolute"
          zIndex={-1}
          width="100%"
          background={extensionBackgroundColor}
          height={['100%', '100%', '100%', `${sliderHeight}px`]}
        />
        <Container
          maxW="container.xl"
          padding={['0', '1rem']}
          height={'100%'}
          position="relative"
          className="custom-slider-container"
        >
          <Img
            src={imageUrl}
            sx={{
              position: 'absolute',
              bottom: 0,
              zIndex: -1,
              width: ['100%', '100%', '100%', 'auto'],
              // objectFit: 'cover',
              height: ['100%', '100%', '100%', `${sliderHeight}px`],
              objectFit: ['contain', 'contain', 'cover', 'cover'],
              objectPosition: [
                'bottom right',
                'bottom right',
                'bottom center',
                'bottom center'
              ]
            }}
          />
          <VStack
            alignItems="start"
            justifyContent="center"
            height="100%"
            maxWidth={['260px', '260px', '260px', '400px']}
            padding={`${paddingTop}px 0 ${paddingBottom}px 0`}
            paddingLeft={['1rem', '1rem', '1rem', '1rem']}
          >
            <Text
              textStyle="h2"
              fontSize={[toRem(24), toRem(24), toRem(24), toRem(32)]}
              textTransform="uppercase"
              as="h2"
            >
              {title}
            </Text>
            <Text
              as="p"
              fontSize={[toRem(14), toRem(14), toRem(14), toRem(16)]}
              style={{ margin: 0 }}
            >
              {content}
            </Text>
            <Button
              backgroundColor="buttonPrimary"
              as="a"
              href={buttonUrl}
              target={target}
              padding={`${toRem(7.5)} ${toRem(27)}`}
            >
              {buttonText}
            </Button>
          </VStack>
        </Container>
      </Box>
    );
  };

  const convertToFullUrl = (url?: string) => {
    return url?.startsWith('http') ? url : HTTP.defaults.baseURL + '/cms' + url;
  };

  useEffect(() => {
    const fetcher = async (url: string) => {
      setIsLoading(true);
      const data = await fetch(url);
      let parsed;
      try {
        parsed = await data.json();
      } catch {}
      const result = (parsed || []) as BannerType[];

      const filteredResult = Array.from(result).filter((banner) => {
        return (
          !!banner.attributes.pictureMobile.data ||
          !!banner.attributes.picture.data
        );
      });

      setBanners(filteredResult);
      setIsLoading(false);
      document.documentElement.dataset.sliderLoaded = '';
    };

    try {
      void fetcher(
        `/api/cms/banner?locale=${getFallbackLanguage(
          lang
        )}&licences=${license}&positions=top&random=false&categoryId=${categoryId}&limit=5`
      );
    } catch (error) {
      setIsLoading(false);
      document.documentElement.dataset.sliderLoaded = '';
      setIsBannerError(true);
    }
  }, [lang, categoryId, license]);

  const hasBanners = banners?.length > 0 && !isLoading && !isBannerError;

  return (
    <>
      {isLoading ? (
        <HStack justifyContent="center" alignItems="center" width="100%">
          <CircularProgress
            size="3em"
            isIndeterminate
            color="buttonPrimary"
            title={t('common:loadingGeneric')}
          />
        </HStack>
      ) : hasBanners ? (
        <Slide
          sliderTestId="slider-banner"
          containerStyle={{
            ...props,
            height: `${sliderHeight}px`
          }}
          bulletClass={customBulletName}
          bulletActiveClass={customBulletActiveName}
          bulletStyles={bulletStyles}
          showArrowNavigation={!isMobile}
          loop
        >
          {banners?.map((banner) => {
            return (
              <SlideItem
                key={banner.id}
                buttonText={banner.attributes.cta}
                buttonUrl={banner.attributes.redirectUrl}
                content={banner.attributes.description}
                imageUrl={convertToFullUrl(
                  isMobile
                    ? banner?.attributes.pictureMobile.data?.attributes.url
                    : banner?.attributes.picture.data?.attributes.url
                )}
                title={banner.attributes.title}
                containerStyle={{ height: `${sliderHeight}px` }}
                target={banner.attributes.ctaTarget}
                extensionBackgroundColor={banner.attributes.extensionColorHex}
              />
            );
          })}
        </Slide>
      ) : null}
    </>
  );
};
export default FullSlider;
