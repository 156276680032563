import { useFilters } from '@/context/Filters';
import { Box, BoxProps, Button, HStack, Image } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import GameImageAndTitle from './GameImageAndTitle';
import { SectionTitle } from './SectionDescription';
import { toRem } from '@/helpers/toRem';
import { DataStoreSections, GameSection } from './types';
import { DomainLicense, getFullUrlByLicense } from '@/utils/multiDomains';
import { GameCatalogItem } from '@/services/getCatalog';
import { useCatalog } from '@/context/Catalog';
import {
  PaginationLine,
  PaginationLineRenderItemProps
} from '@/components/Pagination';
import usePagination from '@/hooks/usePagination';
import { useCatalogBreakpoints } from '@/hooks/useCatalogBreakpoints';
import { useMemo } from 'react';
import { ChevronButtonRound } from '@/components/Button/ChevronButtonRound';

export const TilesSection = ({
  title,
  section,
  icon,
  seeAllRoute,
  nbOfImages = 4,
  isFilterOpen,
  ...props
}: TilesSectionProps) => {
  const { filteredGames } = useFilters();
  const { dataStore } = useCatalog();
  const { t } = useTranslation();
  const { gridItemsCount } = useCatalogBreakpoints(isFilterOpen ?? true);

  const sectionName = Object.values(dataStore.sections || {}).find(
    (x) => x.id === section
  )?.name;

  const data = useMemo(() => {
    if (section) {
      return filteredGames
        ?.filter(
          (game) =>
            // ensure that the game has at least one section
            // when the game is not within any section, sections is an empty array
            !Array.isArray(game.sections) &&
            (game.sections as GameSection)[section] !== undefined
        )
        .sort((a: GameCatalogItem, b: GameCatalogItem) => {
          return (
            (a.sections as GameSection)[section] -
            (b.sections as GameSection)[section]
          );
        });
    }
  }, [filteredGames, section]);

  const renderItemProps = useMemo(() => ({ sectionName }), [sectionName]);

  const hasPagination =
    gridItemsCount > 2 && (data?.length ?? 0) >= gridItemsCount;

  const {
    gotoNextPage,
    gotoPreviousPage,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    totalPage,
    setCurrentPage
  } = usePagination({
    initialData: data ?? [],
    perPage: gridItemsCount!,
    needFullPage: true
  });

  if (data && data?.length <= 4) {
    return null;
  }

  return (
    <Box {...props} data-testid="TilesSection">
      <HStack justifyContent={'flex-start'} mb={6} alignItems={'center'}>
        <HStack mr="auto">
          <Image width={30} height={30} src={icon} alt={title} />
          <SectionTitle fontSize={toRem(18)} fontWeight="500">
            {title}
          </SectionTitle>
        </HStack>

        {hasPagination ? (
          <>
            <ChevronButtonRound
              aria-label="previous page"
              disabled={!hasPreviousPage}
              onClick={() => {
                gotoPreviousPage();
              }}
              direction={'left'}
            />
            <ChevronButtonRound
              aria-label="next page"
              disabled={!hasNextPage}
              onClick={() => {
                gotoNextPage();
              }}
              direction={'right'}
            />
          </>
        ) : null}

        <HStack>
          <Button
            as="a"
            variant="outline"
            lineHeight={5}
            height={9}
            href={seeAllRoute}
            data-testid="TilesSectionButtonSeeAllRoute"
          >
            {t('catalog:seeAll')}
          </Button>
        </HStack>
      </HStack>

      <PaginationLine
        currentPage={currentPage}
        totalPage={totalPage}
        setCurrentPage={setCurrentPage}
        width={'calc(100% + 1rem)'}
        maxWidth="calc(100% + 1rem)"
        ml={'-.5rem'} // - itemGap / 2
        mr={'-.5rem'}
        data={data!}
        itemsPerPage={gridItemsCount}
        hasScroll={!hasPagination}
        itemGap={4}
        renderItem={PaginationLineItem}
        renderItemProps={renderItemProps}
        css={{
          // remove all unwanted styles set in GameImageAndTitle
          // to have correct responsive behavior
          '> div > div > div': {
            width: '100%',
            maxWidth: '100%',
            minWidth: '0',
            minHeight: '0'
          },
          overflow: 'scroll',
          overflowY: 'hidden',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      />
    </Box>
  );
};

const PaginationLineItem = ({
  item,
  sectionName,
  ...props
}: PaginationLineRenderItemProps<GameCatalogItem> & {
  sectionName: string;
}) => {
  return (
    <GameImageAndTitle
      data-testid={'TilesSectionGame'}
      key={item.id}
      game={item}
      section={sectionName}
      {...props}
    />
  );
};

export type TilesSectionProps = BoxProps & {
  section?: string;
  title: string;
  icon: string;
  seeAllRoute: string;
  nbOfImages: number;
  isFilterOpen?: boolean;
};

export interface TilesSectionDataType {
  id: string;
  title: string;
  icon: string;
  url: string;
}

export function getTilesSectionsData(
  sections: DataStoreSections,
  locale: string,
  licence: keyof DomainLicense
): TilesSectionDataType[] {
  const sectionsArray = Object.values(sections).sort(
    (s1, s2) => s1.order - s2.order
  );

  return sectionsArray
    .map((section) => {
      const { id, name, slug } = section;
      return {
        id,
        title: name,
        icon: section.mediaUrls.icon,
        url: `${getFullUrlByLicense(licence, locale)}/section/${slug}`
      } as TilesSectionDataType;
    })
    .filter((section) => Object.keys(section).length > 0);
}
