import { useFilters } from '@/context/Filters';
import useMediaQuery from '@/hooks/useMediaQuery';
import { ArrowLeft, ArrowRight } from '@/theme/Icons';
import {
  Box,
  ComponentWithAs,
  HStack,
  Icon,
  IconButton,
  IconProps
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import { MotionButton } from '../Motion';
import GameImageAndTitle from './GameImageAndTitle';
import { SectionTitle } from './SectionDescription';
import { toRem } from '@/helpers/toRem';
import WithFallbackComponent from '@/hoc/WithFallbackComponent';
import NoGameFallback from '../Fallback/NoGameFallback';
import { useCatalogBreakpoints } from '@/hooks/useCatalogBreakpoints';
export type TilesAllType = {
  title: string;
  iconSection?: ComponentWithAs<'svg', IconProps>;
  cssScroll?: boolean;
  nbOfImages: number;
  isFilterOpen?: boolean;
};
export const TilesAll = ({
  title,
  iconSection,
  cssScroll = false,
  nbOfImages,
  isFilterOpen = true
}: TilesAllType) => {
  const { filteredGames } = useFilters();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: md)');
  const initRowGames = isMobile ? 3 : 2; // mobile: 3 rows, desktop: 2rows
  const initNbOfGames = (isMobile ? 2 : nbOfImages) * initRowGames;
  const [nbGames, setNbGames] = useState<number>(initNbOfGames);
  const [isLazyLoaded, setIsLazyLoaded] = useState<boolean>(false);
  const data = filteredGames;
  const endGame = filteredGames.length;
  const dataFiltered = data.slice(0, nbGames);
  const refRender = useRef<boolean>(true);

  const { gridItemsCount } = useCatalogBreakpoints(isFilterOpen);

  const onPrevClick = () => {
    if (!ref?.current) return;
    const left = 0;
    ref?.current?.scrollBy({
      left: left - 200,
      behavior: 'smooth'
    });
  };

  const onNextClick = () => {
    const left = 0;
    ref?.current?.scrollBy({
      left: left + 200,
      behavior: 'smooth'
    });
  };

  const lazyLoading = () => {
    setNbGames((games) => {
      setIsLazyLoaded(true);
      return (games || 0) + nbOfImages * initRowGames;
    });
  };

  useEffect(() => {
    setIsLazyLoaded(false);
  }, [isLazyLoaded]);

  useEffect(() => {
    const firstRender = refRender.current;
    if (firstRender) {
      refRender.current = false;
    } else {
      // user can hide/show filter so number of games per row is different (4 or 5)
      // fill empty space of games to have full row of games
      const filler =
        nbGames % nbOfImages === 0
          ? nbGames
          : nbOfImages - (nbGames % nbOfImages) + nbGames;

      setNbGames(filler);
    }
  }, [isFilterOpen, nbOfImages, nbGames]);

  return (
    <>
      <HStack justifyContent={'space-between'}>
        <HStack>
          <Icon as={iconSection} width={30} height={30} />
          <SectionTitle fontSize={toRem(18)} fontWeight="500">
            {title || ''}
          </SectionTitle>
        </HStack>
      </HStack>

      <WithFallbackComponent
        predicat={dataFiltered.length > 0}
        FallbackComponent={NoGameFallback}
      >
        <HStack>
          {cssScroll ? (
            <Box>
              <IconButton
                onClick={onPrevClick}
                variant="ghost"
                aria-label="go left"
                _focus={{
                  color: '#fff'
                }}
                icon={<ArrowLeft width={30} />}
              />
              <IconButton
                onClick={onNextClick}
                variant="ghost"
                aria-label="go right"
                _focus={{
                  color: '#fff'
                }}
                icon={<ArrowRight width={30} />}
              />
            </Box>
          ) : null}
        </HStack>
        {/* <MotionHStack
        spacing="1.8rem"
        align="baseline"
        css={cssScroll ? ScrollWithoutScrollBar : {}}
        paddingY="1rem"
        initial={'initial'}
        transition={{
          staggerChildren: 0.15,
          delayChildren: 0.5
        }}
        whileInView={'animate'}
        viewport={{ once: true, amount: 0.7 }}
        key={dataFiltered.length}
        width="50%"
        ref={ref}
        flexWrap="wrap"
      >

      </MotionHStack> */}
        <Box
          display={'grid'}
          marginTop="1.44rem"
          marginBottom="2.87rem"
          gridRowGap="1rem"
          gridColumnGap={'1rem'}
          gridTemplateColumns={`repeat(${gridItemsCount}, 1fr)`}
          maxWidth="100%"
          css={{
            // remove all unwanted styles set in GameImageAndTitle
            // to have correct responsive behavior
            '> div > div': {
              width: '100%',
              maxWidth: '100%',
              minWidth: '0',
              minHeight: '0'
            }
          }}
        >
          {dataFiltered?.map((game, index) => (
            <GameImageAndTitle
              game={game}
              key={game?.id}
              // imgSize={nbOfImages === 4 ? 203 : 218}
              // height="175px"
              // nbOfImages={nbOfImages}
              // index={index}
              // fullWidth={true}
            />
          ))}
        </Box>
        {nbGames < endGame ? (
          <HStack width={'100%'} justifyContent="center">
            <MotionButton
              lineHeight={5}
              height={9}
              onClick={lazyLoading}
              isLoading={isLazyLoaded}
              disabled={nbGames >= endGame}
              size="md"
              background="buttonPrimary"
            >
              {t('catalog:showMore')}
            </MotionButton>
          </HStack>
        ) : null}
      </WithFallbackComponent>
    </>
  );
};
