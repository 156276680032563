import { Box, BoxProps, SlideDirection } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import Filters from '../GamesCatalog/Filters';

type UserMenuProps = BoxProps &
  PropsWithChildren & {
    placement?: SlideDirection;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };

const CatalogFilter = ({
  placement = 'left',
  children,
  onClose,
  onOpen,
  isOpen,
  ...props
}: UserMenuProps) => {
  return isOpen ? (
    <Box display={['none', 'flex']} width="100%" {...props}>
      {children}
      <Filters />
    </Box>
  ) : (
    <>{children}</>
  );
};

export default CatalogFilter;
